import {AbsoluteCenter, Box, Center} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useVuplex} from '../../../hooks/useVuplex';

const MinimapMinimalOverlay = () => {
  // time
  const [currentTime, setCurrentTime] = useState('');
  const intervalId = useRef();

  useEffect(() => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }));
    }, 1000);
  }, [])


  // location
  const [locationName, setLocationName] = useState('');
  useVuplex({
    locationName: (data) => {
      setLocationName(data);
    }
  }, []);

  const borderHeight = 1;
  const borderWidth = 1;

  return (
    <>
      <Box
        bg="blackAlpha.500"
        top={0}
        left={0}
        h="15vh"
        w="100vw"
        position="fixed"
        color="white"
        fontSize="3em"
        fontWeight="bold"
      >
        <AbsoluteCenter>
          {currentTime}
        </AbsoluteCenter>
      </Box>

      <Box
        bg="blackAlpha.500"
        bottom={0}
        left={0}
        h="20vh"
        w="100vw"
        position="fixed"
        paddingX="20%" // TODO: https://devdojo.com/temani-afif/wrapping-text-inside-a-circular-shape
        color="white"
        fontSize="2.5em"
        fontWeight="bold"
        textAlign="center"
      >
        <Center>
          {locationName}
        </Center>
      </Box>

      <Box // This is a mask to match the round corners of Unity ToolPanel
        borderColor="black"
        shadow="dark-lg"
        borderRadius="50%"
        borderWidth="0px"
        h={`${100 + borderHeight * 2}vh`}
        left={`${-borderWidth}vw`}
        p={0}
        pointerEvents="none"
        position="fixed"
        top={`${-borderHeight}vh`}
        w={`${100 + borderWidth * 2}vw`}
        zIndex="tooltip"
      />
    </>
  );
};

export default MinimapMinimalOverlay;
