import {Button, IconButton, Image} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {GiBasketballBall} from 'react-icons/gi';
import SparkGold from '../assets/freemium/Spark Gold.png';
import {disabledPremiumStripesBgDark} from '../helpers/styles';
import {useInAppTooltip} from '../hooks/useInAppTooltip';
import {isPermissionPremium, usePermissions} from '../hooks/usePermissions';
import {sendAudioClick, sendAudioHover, sendUpgrade} from '../vuplex';
import HasPermission from './HasPermission';


const ButtonCircleIcon = ({
  isSelected,
  icon=<GiBasketballBall size="1.5em"/>,
  children,
  disabledReason,
  forceShowPremiumStar,
  isDisabled,
  isRound = true,
  onClick = _.noop,
  onRef,
  permission,
  permissionBlockedProps,
  permissionTooltip,
  tooltip,
  upgradeClickMetadata = { descriptor: 'Generic disallowed button - disregard IapType', packageName: null },
  ...buttonProps
}) => {


  const isPremium = isPermissionPremium(permission);
  const hasPermission = usePermissions(permission);

  const sharedProps = {
    ...buttonProps,
  };

  const content = (
    <>
      {children}
      {(forceShowPremiumStar || (isPremium && hasPermission))&& (
        <Image
          h={6}
          left="0.5em"
          marginLeft="-1em"
          position="relative"
          src={SparkGold}
          top="-0.5em"
        />)}
    </>);

  const tooltipProps = useInAppTooltip(disabledReason || tooltip);
  const tooltipNoPermsProps = useInAppTooltip(tooltip);

  return (
    <HasPermission
      fallbackContent={ // TODO
        <Button
          {...sharedProps}
          {...permissionBlockedProps}
          {...tooltipNoPermsProps}
          bg={disabledPremiumStripesBgDark}
          isDisabled
          onClick={() => {
            sendUpgrade(upgradeClickMetadata);
          }}
          opacity="1!important"
          variant="buttonMinor"
        >
          {content}
        </Button>
      }
      permission={permission}
    >
      <IconButton
        color={isSelected ? 'wooorldBlue._' : 'black24'}
        {...sharedProps}
        {...tooltipProps}
        icon={icon}
        isRound
        onClick={(e) => {
          onClick(e);
          sendAudioClick(e);
        }}
        onPointerEnter={(e) => {
          tooltipProps.onPointerEnter(e);
          sendAudioHover(e);
        }}
        ref={onRef}
        variant="buttonMinor"
      />
    </HasPermission>
  );
};

export default ButtonCircleIcon;
