import {ChevronDownIcon, ChevronUpIcon, SearchIcon} from '@chakra-ui/icons';
import {Flex, IconButton, InputGroup, InputLeftElement, InputRightElement, keyframes} from '@chakra-ui/react';
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from '@choc-ui/chakra-autocomplete';
import axios from 'axios';
import _ from 'lodash';
import React, {useState} from 'react';
import {FaMicrophone} from 'react-icons/fa';
import ButtonMinor from '../../components/ButtonMinor';
import {sendAudioHover, sendVoiceInputRequest} from '../../vuplex';
import InAppTooltip from './UnityReactComponents/InAppTooltip';


const searchDebounced = _.debounce(async ({input, setResults, setIsLoading}) => {
  setIsLoading(true);
  const res = await axios.get(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(input)}&format=jsonv2`);
  console.log(res.data);
  setResults(res.data);
  setIsLoading(false);
}, 500);

const pulse = keyframes`
  from {
    color: darkred;
  }
  to {
    color: red;
  }
`;

const OpenStreetMapAutoComplete = ({
  onSelect = () => {
  },
  ...inputGroupProps
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);


  const [isVoicePending, setIsVoicePending] = useState(false);

  return (<>
    <AutoComplete // https://github.com/anubra266/choc-autocomplete
      autoFocus={false}
      closeOnBlur
      disableFilter
      focusInputOnSelect={false}
      isLoading={isLoading}
      listAllValuesOnFocus
      openOnFocus
      restoreOnBlurIfEmpty
      selectOnFocus
      suggestWhenEmpty

      onSelectOption={({item, selectMethod, isNewInput}) => {
        // console.log(item, selectMethod, isNewInput);
        const {originalValue: {lat, lon, display_name, name}} = item;
        console.log(lat, lon);
        onSelect({
          display_name,
          lat: parseFloat(lat),
          lon: parseFloat(lon),
          name,
        });
      }}
    >
      {({isOpen, onOpen, onClose}) => {

        return (
          <>
            <InputGroup {...inputGroupProps}>
              <InputLeftElement color="black24" pointerEvents="none">
                <SearchIcon/>
              </InputLeftElement>

              <AutoCompleteInput
                background="white!important"
                borderRadius="full"
                onChange={(e) => {
                  searchDebounced({
                    input: e.currentTarget.value,
                    setResults,
                    setIsLoading,
                  });
                }}
                variant="filled"
              />

              <InputRightElement w={20}>
                <Flex gap={0} alignItems="center">
                  <ButtonMinor
                    animation={isVoicePending ? `${pulse} 1.5s infinite alternate ease-in-out` : undefined}
                    bg="white"
                    filter="none!important" // prevent focus state turning it grey
                    h="2.2em" // prevent busting out the top when input is focused
                    isRound={false}
                    onClick={async () => {
                      setIsVoicePending(true);
                      const res = await sendVoiceInputRequest().catch(() => {
                        console.error('failed to get voice input');
                      });
                      setIsVoicePending(false);
                      if (!res) return;
                      searchDebounced({
                        input: res,
                        setResults,
                        setIsLoading,
                      });
                      searchDebounced.flush(); // .flush() invokes immediately
                      onOpen();
                    }}
                    p={0}
                    tooltip="Click, then begin speaking"
                  >
                    <FaMicrophone
                      size="1.25em"
                    />
                  </ButtonMinor>

                  {/*<InAppTooltip message="Click, then begin speaking">*/}

                  {/*  <IconButton*/}
                  {/*    // animation={`${pulse} 1.5s infinite alternate ease-in-out`}*/}
                  {/*    animation={voiceInput.isLoading ? `${pulse} 1.5s infinite alternate ease-in-out` : undefined}*/}
                  {/*    borderRadius="full"*/}
                  {/*    color={'black24'}*/}
                  {/*    _hover={{*/}
                  {/*      color: 'wooorldBlue._',*/}
                  {/*    }}*/}
                  {/*    icon={<FaMicrophone size="1.5em"/>}*/}
                  {/*    isDisabled={isDisabled || !hasPermission}*/}
                  {/*    lineHeight="1.5em"*/}
                  {/*    onClick={handleClickVoiceInput}*/}
                  {/*    onPointerEnter={(e) => {*/}
                  {/*      sendAudioHover(e);*/}
                  {/*    }}*/}
                  {/*    variant="buttonDiminished"*/}
                  {/*  />*/}
                  {/*</InAppTooltip>*/}

                  <ButtonMinor
                    bg="none"
                    onClick={() => {
                      if (isOpen) onClose();
                      else onOpen();
                    }}
                    p={0}
                  >
                    {isOpen
                      ? <ChevronUpIcon/>
                      : <ChevronDownIcon/>}
                  </ButtonMinor>
                </Flex>


              </InputRightElement>
            </InputGroup>

            <AutoCompleteList>
              {results.map(({
                display_name,
                lat,
                lon,
                name,
                place_id,
              }, idx) => (
                <AutoCompleteItem
                  key={idx}
                  color="black24"
                  label={display_name}
                  value={{lat, lon, display_name, name}}
                  alignItems="center"
                  fontSize="1.4em"
                >
                  {display_name}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </>
        );
      }}
    </AutoComplete>

  </>);
};

export default OpenStreetMapAutoComplete;
