import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton, Select,
  Stack,
  Switch, useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {BiListPlus} from 'react-icons/bi';
import {IoIosBasketball} from 'react-icons/io';
import {useSelector} from 'react-redux';
import {apiPost} from '../../api';
import {useVuplex} from '../../hooks/useVuplex';
import {
  sendBasketball,
  sendDevConsoleClearRequest,
  sendDevConsoleDictationActivate,
  sendDevConsoleEntriesRequest
} from '../../vuplex';
import DevConsoleCallStackDrawer from './DevConsoleCallStackDrawer';
import InAppWebBrowser from './UnityReactComponents/InAppWebBrowser';
import InputBar from './InputBar';
import PageTitle from './PageTitle';
import WebBookmarkPoimandresMarket from './WebBookmarkPoimandresMarket';
import WebBrowserLauncher from './WebBrowserLaunchButton';

const scrollToBottom = () => window.scrollTo(0, document.body.scrollHeight);


const DevConsole = ({}) => {
  const [showEntries, setShowEntries] = useState({
    Dictation: true,
    DictationPartial: false,
    Log: true,
    Warning: true,
    Error: true,
  });
  const [autoScroll, setAutoScroll] = useState(true);
  const [browsers, setBrowsers] = useState([]);
  const [isGodModeOn, setIsGodModeOn] = useState(false);
  const [entries, setEntries] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagSelected, setTagSelected] = useState('');
  const [stackTraceEntry, setStackTraceEntry] = useState(null);
  const textInputField = useSelector(s => s.ui.txtInputDevConsole.textInputField);

  useVuplex({
    logEntries: ({logEntries}) => {
      setEntries((currEntries) => [
        ...currEntries,
        ...logEntries,
      ]);
    },
    logEntry: ({logEntry}) => {
      setEntries((currEntries) => [
        ...currEntries,
        logEntry,
      ]);
    },
    tags: ({tags}) => {
      setTags(tags);
    },
  });

  useEffect(() => {
    sendDevConsoleEntriesRequest(entries.length);
  }, []);

  useEffect(() => {
    if (autoScroll) scrollToBottom();
  }, [autoScroll, entries]);

  // TODO: turn off autoscroll when scrolling
  useEffect(() => {
    const onScroll = (e) => {
      // console.log('scroll', e)
      // TODO: autoScroll = false when user scrolls
      //  but no easy way to determine if scroll originated from user or autoscroll
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const toast = useToast();


  const handleSaveClick = async () => {
    const res = await apiPost(`/users/devConsoleSave`, entries).catch(err => {
      toast({
        description: err.message,
        title: `Failed to save entries`,
        status: 'error',
      });
    });

    toast({
      description: `${entries.length} entries saved to DB`,
      status: 'success',
    });

  }

  return (
    <>
      <PageTitle title="Dev Console - Wooorld"/>

      <Card bg="darkcyan">
        <CardHeader><Heading as="h2" size="xs">Launch a realtime browser</Heading></CardHeader>
        <CardBody>
          <IconButton color="orange" icon={<IoIosBasketball size="lg" />} onClick={sendBasketball} />

          <WebBookmarkPoimandresMarket />

          {
            browsers.map(key => (
              <WebBrowserLauncher
                key={key}
                onClose={() => {
                  setBrowsers(browsers => _.without(browsers, key));
                }}
              />))
          }
        </CardBody>
        <CardFooter>
          <Flex w="full" justifyContent="space-between">
            <IconButton
              icon={<BiListPlus size="lg"/>}
              onClick={() => {
                setBrowsers(browsers => [...browsers, _.uniqueId('browser')]);
              }}
            />

            <Button
              onClick={() => {
                setIsGodModeOn(!isGodModeOn);
              }}
            >
              😇 {isGodModeOn ? 'Close' : 'Open'} Goood
            </Button>

            <Button
              onClick={sendDevConsoleDictationActivate}
            >
              🎙Activate Dictation
            </Button>
          </Flex>

          {isGodModeOn && (
            <InAppWebBrowser
              url={`https://${window.location.host}/goood`}
            />)}
        </CardFooter>
      </Card>


      <InputBar stateKey="txtInputDevConsole">
        <Stack w="full">
          <Flex gap={2} justifyContent="flex-end">
            <Button
              onClick={(e) => {
                sendDevConsoleEntriesRequest(entries.length);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Get Logs
            </Button>


            <Button
              onClick={handleSaveClick}
            >
              💾 Save Entries
            </Button>

            <Button
              onClick={(e) => {
                sendDevConsoleClearRequest();
                setEntries([]);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Clear
            </Button>


            <FormControl display="flex" alignItems="center" w="auto">
              <FormLabel htmlFor="autoScroll" mb={0} mr={1}>
                AutoScroll
              </FormLabel>
              <Switch
                id="autoScroll"
                isChecked={autoScroll}
                onChange={({target: {checked}}) => {
                  setAutoScroll(checked);
                  if (checked) scrollToBottom();
                }}
              />
            </FormControl>
          </Flex>


          <Flex gap={2} paddingTop={2} justifyContent="space-between">

            <Select
              colorScheme="wooorldBlue"
              onChange={({ target: { value }}) => setTagSelected(value)}
              placeholder="filter by tag"
              value={tagSelected}
            >
              {tags.map(t => <option key={t} value={t}>{t}</option>)}
            </Select>


            <Flex gap={3}>
              {
                ['Log', 'Warning', 'Error', 'Dictation', 'DictationPartial'].map(entryType => {
                  const colorScheme = { // https://v2.chakra-ui.com/docs/styled-system/theme#colors
                    Error: 'red',
                    Log: 'cyan',
                    Warning: 'yellow',
                    Dictation: 'green',
                    DictationPartial: 'teal'
                  }[entryType];

                  return (
                    <FormControl key={entryType} display="flex" alignItems="center" w="auto">
                      <FormLabel color={`${colorScheme}.700`} htmlFor={entryType} mb={0} mr={1}>
                        {entryType}
                      </FormLabel>
                      <Switch
                        colorScheme={colorScheme}
                        id={entryType}
                        isChecked={showEntries[entryType]}
                        onChange={({target: {checked}}) => {
                          setShowEntries({
                            ...showEntries,
                            [entryType]: checked,
                          });
                        }}
                      />
                    </FormControl>
                  );
                })
              }
            </Flex>


          </Flex>
        </Stack>

      </InputBar>


      <Stack>
        {entries
          .map((e, i) => ({...e, ix: i}))
          .filter(({Message}) => !tagSelected || _.startsWith(Message, `[${tagSelected}]`))
          .filter(({LogType, Message}) => showEntries[LogType] && new RegExp(textInputField, 'i').test(Message))
          .map((entry) => {
            const {LogType, Message, StackTrace, ix} = entry;

            return (
              <Code
                bg={{
                  Error: '#FAA',
                  Dictation: '#AFA',
                  DictationPartial: '#AFC',
                  Log: '#AEF',
                  Warning: '#FEA'
                }[LogType]}
                key={ix}
                onClick={() => {
                  setStackTraceEntry(entry);
                }}
              >
                [{ix}]-> {Message}
              </Code>
            );
          })}
      </Stack>

      <DevConsoleCallStackDrawer
        entry={stackTraceEntry}
        onClose={() => {
          setStackTraceEntry(null);
        }}
      />

    </>
  );


};

export default DevConsole;
