import {AbsoluteCenter} from '@chakra-ui/react';
import {AdvancedMarker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clustersRequest} from '../../../reducers/pinsReducer';
import {sendAudioClick, sendPanoLoad} from '../../../vuplex';
import InAppPinsAndClusters from './InAppPinsAndClusters';
import PanoClusterIcon from './PanoClusterIcon';
import PanoIcon from './PanoIcon';

const MinimapPinsAndClusters = ({onClickCluster, showInAppPins}) => {
  const dispatch = useDispatch();

  const clusters = useSelector(s => s.pins.clusters.response) || [];
  const worldId = useSelector(s => s.inApp.world._id) || {};

  //TODO maybe hide a pin if pano is loaded. PanoMarker is sometimes in a different location than the pin though so maybe we leave it.
  // const panoIdLoaded = useSelector(s => s.inApp.panoId);
  // const panoLocationLoaded = useSelector(s => s.inApp.panoLocation);

  const map = useMap();
  const maps = useMapsLibrary('maps');
  const [hoveringId, setHoveringId] = useState();

  useEffect(() => {
    if (!map || !maps) return;

    const refresh = _.debounce(async () => {
      const bounds = map.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      console.log(northEast.lat())
      await dispatch(clustersRequest({
        bounds: {
          topRight: {Latitude: northEast.lat(), Longitude: northEast.lng()},
          bottomLeft: {Latitude: southWest.lat(), Longitude: southWest.lng()},
        },
        worldId,
        zoom: map.getZoom() - 1,
      }));
      setHoveringId(null);
    }, 1000);

    const dragEndListenerId = map.addListener('dragend', refresh);
    const zoomChangedListenerId = map.addListener('zoom_changed', refresh);

    return () => {
      window.google.maps.event.removeListener(dragEndListenerId);
      window.google.maps.event.removeListener(zoomChangedListenerId);
    };
  }, [map, maps, worldId]);

  // only show the first instance of a pinned pano
  const sortedUniqueClusters = _(clusters)
    .orderBy('id')
    .uniqWith((a, b) => a.streetViewId && a.streetViewId === b.streetViewId)
    .value();


  return (<>
    {sortedUniqueClusters.map((clusterData, idx) => {
      const {
        location: {Latitude: lat, Longitude: lng},
        isInPrivateFolder,
        isInSharedFolder,
        numPins,
        streetViewId,
        zoomToExpand,
      } = clusterData;

      const isCluster = !!numPins;

      return (
        <AdvancedMarker
          key={idx}
          onClick={(e) => {
            e.stop();
          }}
          position={{lat, lng}}
        >
          <AbsoluteCenter pointerEvents="none">
            {isCluster
              ? (
                <PanoClusterIcon
                  count={numPins}
                  onClick={(e) => {
                    sendAudioClick(e);
                    onClickCluster();
                    map.setZoom(zoomToExpand + 1);
                    map.setCenter({lat, lng});
                  }}
                  size="5em"
                />)
              : ((hoveringId === null) || (idx === hoveringId)) && (
                <PanoIcon
                  isPrivate={isInPrivateFolder}
                  isShared={isInSharedFolder}
                  onClick={(e) => {
                    sendAudioClick(e);
                    sendPanoLoad({
                      lat,
                      lon: lng,
                      panoId: streetViewId,
                    });
                    setHoveringId(null);
                  }}
                  onPointerEnter={() => {
                    setHoveringId(idx);
                  }}
                  onPointerLeave={() => {
                    setHoveringId(null);
                  }}
                  pin={clusterData}
                  size="5em"
                  streetViewId={streetViewId}
                />
              )}
          </AbsoluteCenter>

        </AdvancedMarker>
      );
    })}

    {showInAppPins && <InAppPinsAndClusters />}
  </>);
};

export default MinimapPinsAndClusters;
