import WebBrowserBookmarkLaunchButton from './WebBrowserBookmarkLaunchButton';

const jsOnLoad = `
document.querySelectorAll('img').forEach(img => {
  img.className = img.className.replace('pointer-events-none', '');
  img.onpointerenter = (e) => {
    const modelUrl = e.target.src.replace('thumbnail.png', 'model.gltf');
    
    // send spawnModelButton vuplex with modelUrl
    window.vuplex && window.vuplex.postMessage({
      NormalizedX: e.clientX / window.innerWidth,
      NormalizedY: 1 - e.clientY / window.innerHeight,
      Type: 'gltfCreateButton',
      URL: modelUrl,
    });
  };
});
`

const WebBookmarkPoimandresMarket = () => (
  <WebBrowserBookmarkLaunchButton
    executeJavascriptOnLoad={jsOnLoad}
    label="market.pmnd.rs"
    url="https://market.pmnd.rs/"
  />
)

export default WebBookmarkPoimandresMarket;
