import {
  Avatar,
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {HiDotsHorizontal} from 'react-icons/hi';
import {MdVrpano} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {useEnterKey} from '../../hooks/useEnterKey';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import {useRetryOnAccessTokenRefresh} from '../../hooks/useRetryOnAccessTokenRefresh';
import {
  folderEditFormSet,
  pinEditFormSaveRequest,
  pinEditFormSet,
  pinEditFormUpdate,
  pinsBalanceRequest
} from '../../reducers/pinsReducer';
import {sendAudioClick, sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import PermissionBadge from './PermissionBadge';
import PinEditFoldersList from './PinEditFoldersList';
import PinFolderEditForm from './PinFolderEditForm';
import InAppTooltip from './UnityReactComponents/InAppTooltip';
import UpgradeButton from './UpgradeButton';

const PinEditForm = () => {
  const folderEditForm = useSelector(s => s.pins.folderEditForm);
  const pinEditForm = useSelector(s => s.pins.pinEditForm);
  const {response: folders} = useSelector(s => s.pins.foldersListUser);

  const navigate = useNavigate();
  const toast = useToast();
  const {isPrivate: isPrivateWorld, isSolo: isSoloWorld} = useSelector(s => s.inApp.world.rules) || {};
  const currentWorldId = useSelector(s => s.inApp.world._id) || {};


  const {
    _id,
    address,
    folderId,
    label,
    likes = 0,
    ownerAlias,
    ownerProfilePhotoURL,
    suggestedLabel,
  } = pinEditForm;
  const isNew = !_id;

  const folderSelected = useSelector(s => (s.pins.foldersListUser.response || []).find(f => f._id === folderId));

  const {pinsBalance} = useSelector(s => s.pins.pinsBalanceRequest.response) || {};
  const hasBalance = !isNew || pinsBalance > 0;
  const showBalance = hasBalance && isNew && pinsBalance <= 10;


  const dispatch = useDispatch();

  const showFolderEditForm = !_.isEmpty(folderEditForm);
  const isFolderFormValid = showFolderEditForm
    ? !!folderEditForm.label
    : !!folderId;

  const isInPrivateFolder = (showFolderEditForm ? folderEditForm : folderSelected || {}).isSolo;
  const isShared = (showFolderEditForm ? folderEditForm : folderSelected || {}).sharedInWorld === currentWorldId;

  const isFormValid = isFolderFormValid && label && hasBalance;

  const [recentFolderId, setRecentFolderId] = useLocalStorageState('pinSaveRecentFolder', null);


  useEffect(() => {
    if (isNew && !folderId) { // select public preset by default if new pin

      if (recentFolderId)
        dispatch(pinEditFormUpdate({folderId: recentFolderId}));
      else {
        const publicPreset = _.find(folders, f => f.presetName === 'publicUncategorized');
        if (publicPreset) dispatch(pinEditFormUpdate({folderId: publicPreset._id}));
      }
    }
  }, [folders, isNew, folderId]);

  useEnterKey(/*blur Textarea*/);

  useRetryOnAccessTokenRefresh(() => dispatch(pinsBalanceRequest({})));

  const [isEditingLabel, setIsEditingLabel] = useState(false);

  return (
    <Card
      bg="whiteAlpha.600"
      borderRadius="2xl"
      m={4}
      p={2}
    >
      <CardHeader p={1}>
        <Flex justifyContent="center" spacing="1">
          <Heading size="sm">Save Place</Heading>
        </Flex>
      </CardHeader>

      <CardBody p={2} m={4} marginBottom={0}>
        <Stack gap={8}>
          <Flex>
            <Stack alignItems="center" w="50%">
              <Text>Add a description</Text>
              <Card
                bg="whiteAlpha.700"
                borderRadius="2xl"
                fontSize=".8em"
                h="11em"
                w="19em"
                overflow="hidden"
              >
                <CardHeader p={2}>
                  <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                    <Flex alignItems="center">
                      <Avatar
                        borderColor="lightgreen"
                        name={ownerAlias}
                        showBorder
                        size="xs"
                        src={ownerProfilePhotoURL}
                      />
                      <Heading
                        marginLeft={1}
                        maxW="5em"
                        fontWeight="normal"
                        size="xs"
                        noOfLines={1}
                      >{ownerAlias}</Heading>
                    </Flex>

                    <Flex alignItems="center" gap={1}>
                      <InAppTooltip message="Place has a 360"><MdVrpano color="grey" size="1.5em"/></InAppTooltip>
                      <PermissionBadge
                        isPin
                        isPrivate={isInPrivateFolder}
                        isShared={isShared}
                        size="1.5em"
                      />
                      <HiDotsHorizontal color="#AAA"/>
                    </Flex>

                  </Flex>
                </CardHeader>

                <CardBody p={1.5}>
                  {!isEditingLabel
                    ? (
                      <InAppTooltip message="Click to edit">
                        <Text
                          fontSize="sm"
                          textAlign="center"
                          noOfLines={2}
                          onClick={(evt) => {
                            sendAudioClick(evt);
                            setIsEditingLabel(true);
                          }}
                        >
                          {/*{isHovering ? address : pinLabel}*/}
                          {label || 'click to add a description'}
                        </Text>
                      </InAppTooltip>
                    )
                    : (
                      <Textarea
                        autoFocus={true}
                        bg="blackAlpha.100"
                        border="none"
                        fontSize="sm"
                        key="placeLabelInput"
                        onChange={({target: {value}}) => {
                          dispatch(pinEditFormUpdate({
                            label: _.replace(value, '\n', ''),
                          }));
                        }}
                        onPointerEnter={() => {
                          sendDragWithinPage();
                        }}
                        onPointerLeave={() => {
                          sendDragToScroll();
                        }}
                        onBlur={(evt) => {
                          setIsEditingLabel(_.isEmpty(_.trim(label || '')));
                          sendDragToScroll();
                        }}
                        overflow="hidden"
                        p={1}
                        resize="none"
                        rows={2}
                        textAlign="center"
                        value={label || ''}
                        w="16.5em"
                      />
                    )}
                </CardBody>

                <CardFooter p={0} paddingX={1} marginTop={0} h="3em">

                  <Flex alignItems="center" justifyContent="space-between" w="full">
                    <Text flexShrink={0} marginLeft={2} noOfLines={1}>{likes} {likes === 1 ? 'like' : 'likes'}</Text>
                  </Flex>

                </CardFooter>
              </Card>


            </Stack>

            <Stack alignItems="center" w="50%">
              <Text>Choose or create a Place List</Text>

              {showFolderEditForm
                ? <PinFolderEditForm suppressSaveButton/>
                : (
                  <PinEditFoldersList
                    onFolderClick={(f) => {
                      if (f) dispatch(pinEditFormUpdate({folderId: f._id}));
                      else dispatch(folderEditFormSet({
                        label: '',
                        isSolo: false,
                        sharedInWorld: '',
                      }));
                    }}
                    selectedFolderId={folderId}
                  />
                )}
            </Stack>
          </Flex>

        </Stack>
      </CardBody>

      <CardFooter p={4}>
        <Flex gap={4} alignItems="center" justifyContent="center" w="full">
          <Box position="relative">
            <ButtonMinor
              isDisabled={!isFormValid}
              onClick={async () => {
                const {error, payload} = await dispatch(pinEditFormSaveRequest());
                if (error) {
                  toast({
                    status: 'error',
                    description: 'Unable to save pin. Please try again.',
                  });
                } else {
                  const {pin: {folderId, isInPrivateFolder, isInSharedFolder}} = payload;

                  setRecentFolderId(folderId);

                  const showInvisibleWarning = isPrivateWorld
                    ? !isSoloWorld && isInPrivateFolder && !isInSharedFolder
                    : isInPrivateFolder;

                  toast({
                    status: 'success',
                    description: `Place saved${showInvisibleWarning ? ' (not visible to others in this world)' : ''}`,
                  });

                  if (showBalance) {
                    toast({
                      status: 'info',
                      description: pinsBalance === 1
                        ? `Upgrade to save more places`
                        : `You may save ${pinsBalance - 1} more ${pinsBalance === 2 ? 'place' : 'places'}`,
                    });
                  }

                  navigate(-1); // close modal
                  _.defer(() => dispatch(pinEditFormSet({})));
                }
              }}

            >
              💾 Save
            </ButtonMinor>

            {!hasBalance && (
              <Center>
                <UpgradeButton
                  descriptor="PinEditForm"
                  top="1.8em"
                  packageName="explorer+"
                  position="absolute"
                  transform="scale(0.8)"
                  zIndex="sticky"
                />
              </Center>
            )}
            {showBalance && (
              <Badge
                bg="wooorldBlue._"
                borderRadius="3xl"
                bottom="-1em"
                color="white"
                paddingX={2}
                position="absolute"
                right={0}
              >
                {pinsBalance}
              </Badge>
            )}
          </Box>


          <ButtonMinor
            onClick={async () => {
              navigate(-1); // close modal
              _.defer(() => dispatch(pinEditFormSet({})));
            }}
          >
            ❌ Cancel
          </ButtonMinor>
        </Flex>
      </CardFooter>
    </Card>
  );


};

export default PinEditForm;
