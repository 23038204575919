import {CloseIcon} from '@chakra-ui/icons';
import {Flex, IconButton} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TbBrowserPlus} from 'react-icons/tb';
import InAppWebBrowser from './UnityReactComponents/InAppWebBrowser';

const WebBrowserBookmarkLaunchButton = ({
  height,
  executeJavascriptOnLoad,
  label,
  url,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex gap={1}>
      {label}

      {!isOpen
        ? (
          <IconButton
            colorScheme={'wooorldBlue'}
            icon={<TbBrowserPlus size="2em"/>}
            isRound
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )
        : (
          <IconButton
            colorScheme={'red'}
            icon={<CloseIcon size="2em"/>}
            isRound
            onClick={() => {
              setIsOpen(false);
            }}/>
        )}

      {isOpen && (
        <InAppWebBrowser
          height={height}
          executeJavascriptOnLoad={executeJavascriptOnLoad}
          url={url}
          width={width}
        />
      )}
    </Flex>
  );
};

export default WebBrowserBookmarkLaunchButton;
