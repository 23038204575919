import {CloseIcon} from '@chakra-ui/icons';
import {Flex, Stack, Text, useToast} from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import ButtonMinor from '../../../../components/ButtonMinor';
import {sendAssistantRequest, sendExploreSearch, sendNavTo, sendPanoLoad} from '../../../../vuplex';

const ToastContent = ({latitude, longitude, placeId, onClose}) => {
  const dispatch = useDispatch();

  const [label, setLabel] = useState('');
  useEffect(() => {

    axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
      .then((res) => {
        console.log(res.data);
        setLabel(res.data.display_name);
      })
      .catch((e) => {
        console.error(e);
      });

  }, []);

  return (
    <Flex bg="#007AFF" borderRadius="lg" color="white" p={2} alignItems="center" gap={2}>
      <Stack>
        <Text textAlign="center">{label}</Text>
        <Text textAlign="center">{latitude}, {longitude}</Text>
        <Flex justifyContent="space-evenly" gap={2}>
          <ButtonMinor
            onClick={() => {
              sendNavTo({
                lat: latitude,
                lon: longitude,
                // zoom: map.getZoom() - zoomOffset,
              });
            }}
            tooltip="Teleport to this location"
          >
            Go here
          </ButtonMinor>

          <ButtonMinor
            onClick={() => {
              sendPanoLoad({lat: latitude, lon: longitude});
              onClose();
            }}>
            Load nearest 360
          </ButtonMinor>

          {label && (
            <ButtonMinor
              onClick={() => {
                sendAssistantRequest({locationName: label});
                onClose();
              }}>
              Fun fact
            </ButtonMinor>
          )}

          {placeId && (
            <ButtonMinor
              onClick={() => {
                sendExploreSearch({lat: latitude, lon: longitude, placeId});
              }}>
              View in Explore
            </ButtonMinor>
          )}

        </Flex>

        {/*<Skeleton isLoaded={!isLoading}>*/}
        {/*  <Box>*/}
        {/*    */}
        {/*  </Box>*/}
        {/*</Skeleton>*/}
      </Stack>

      <ButtonMinor bg="none" borderLeft="solid white 1px" borderRadius={0} onClick={onClose}>
        <CloseIcon color="white"/>
      </ButtonMinor>
    </Flex>
  );
};

const MinimapClickActionToast = ({latitude, longitude, placeId, onClose = _.noop}) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    const closeCurrentToast = () => toast.close(toastIdRef.current);

    if (placeId || latitude || longitude) {
      closeCurrentToast();
      toastIdRef.current = toast({
        render: () => (
          <ToastContent
            latitude={latitude}
            longitude={longitude}
            placeId={placeId}
            onClose={() => {
              closeCurrentToast();
              onClose();
            }}
          />),
        duration: null,
        // icon: <MdVrpano color="#33bbff" size="1.5em"/>,
        isClosable: true,
        position: 'bottom',
      });
    } else {
      closeCurrentToast();
    }

    return closeCurrentToast;
  }, [latitude, longitude, placeId, onClose]);

  // useEffect(() => {
  //   if (panoId) {
  //     dispatch(pinsPanoIdSearchRequest({
  //       isNew: true,
  //       panoId,
  //     }));
  //   }
  // }, [panoId]);

  return null;
};

export default MinimapClickActionToast;
