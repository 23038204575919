import {Stack} from '@chakra-ui/react';
import React from 'react';
import {FaStreetView} from 'react-icons/fa6';
import {MdLocationSearching, MdMyLocation, MdZoomIn, MdZoomOut} from 'react-icons/md';
import {PiGlobeHemisphereEast, PiGlobeHemisphereEastDuotone} from 'react-icons/pi';
import ButtonCircleIcon from '../../../components/ButtonCircleIcon';
import MinimapCompassIcon from './MinimapCompassIcon';

const MinimapControls = ({
  isFixedNorth,
  isMapSynced,
  isStreetViewLayerOn,
  onCompassClick,
  onLocateClick,
  onStreetViewClick,
  onZoomInClick,
  onZoomOutClick,
  onZoomToGlobeClick,
}) => {

  return (
    <Stack marginX={3} marginY={4}>
      <ButtonCircleIcon
        icon={<MdZoomIn size="1.5em"/>}
        onClick={onZoomInClick}
        shadow="dark-lg"
        tooltip="Zoom in"
      />
      <ButtonCircleIcon
        icon={<MdZoomOut size="1.5em"/>}
        onClick={onZoomOutClick}
        shadow="dark-lg"
        tooltip="Zoom out"
      />
      <ButtonCircleIcon
        icon={<PiGlobeHemisphereEastDuotone size="1.5em"/>}
        onClick={onZoomToGlobeClick}
        shadow="dark-lg"
        tooltip="Zoom out to whole world"
      />
      <ButtonCircleIcon
        icon={isStreetViewLayerOn ? <FaStreetView size="1.5em"/> : <FaStreetView size="1.5em"/>}
        isSelected={isStreetViewLayerOn}
        onClick={onStreetViewClick}
        shadow="dark-lg"
        tooltip={isStreetViewLayerOn ? 'Hide 360 layer' : 'Show 360 layer'}
      />
      <MinimapCompassIcon
        isFixedNorth={isFixedNorth}
        onClick={onCompassClick}
      />
      <ButtonCircleIcon
        icon={isMapSynced ? <MdMyLocation size="1.5em"/> : <MdLocationSearching size="1.5em"/>}
        isSelected={isMapSynced}
        onClick={onLocateClick}
        shadow="dark-lg"
        tooltip={isMapSynced ? 'Zoom map to default' : 'Center map on your location'}
      />
    </Stack>
  );

};

export default MinimapControls;
