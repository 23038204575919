import {IconButton} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useState} from 'react';
import {ImCompass2} from 'react-icons/im';
import ButtonCircleIcon from '../../../components/ButtonCircleIcon';
import {useVuplex} from '../../../hooks/useVuplex';

const MinimapCompassIcon = ({isFixedNorth, onClick}) => {
  const [icon, setIcon] = useState(null);

  useVuplex({
    mapNav: ({
      menuHeadingDegrees,
    }) => {
      const style = _.get(icon, 'firstChild.style');
      if (!style) {
        console.warn('unexpected marker dom structure');
        return;
      }

      style.transform = `rotate(${(isFixedNorth ? 0 : -menuHeadingDegrees) - 45}deg)`;
    }
  }, [
    icon,
    isFixedNorth,
  ]);


  return (
    <ButtonCircleIcon
      icon={isFixedNorth ? <ImCompass2 size="1.5em"/> : <ImCompass2 size="1.5em"/>}
      isSelected={!isFixedNorth}
      onClick={onClick}
      onRef={setIcon}
      shadow="dark-lg"
      tooltip={isFixedNorth ? 'Rotate map to match heading' : 'Rotate map so north is always up'}
    />);
};

export default MinimapCompassIcon;
